import Button from '@/components/atoms/Button';
import Slider from '@/components/molecules/Slider';
import { getImage } from '@/services/ecommerce/utils/helper';
import { ActionBlockData } from '@/services/ecommerce/types/blocksTypes';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './most-love-brands.module.scss';
interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const MostLoveBrands: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('py-30 ', styles.most_love_brands)}>
			<div className='container product-slider'>
				<div
					className='text-center '
					style={{
						textAlign: 'center',
						marginBottom: 30
					}}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<Slider
					slidesPerView={3.6}
					spaceBetween={20}
					navigation={true}
					shouldSlidesPerGroup={true}
					responsive={{
						'0': {
							slidesPerView: 1
						},
						'640': {
							slidesPerView: 1
						},
						'768': {
							slidesPerView: 1
						},
						'800': {
							slidesPerView: 1.8,
							spaceBetween: 10
						},
						'1024': {
							slidesPerView: 1.8,
							spaceBetween: 10
						},
						'1280': {
							slidesPerView: 2.3,
							spaceBetween: 10
						},
						'1400': {
							slidesPerView: 3.6,
							spaceBetween: 20
						},
						'1600': {
							slidesPerView: 3.6,
							spaceBetween: 20
						}
					}}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<div className={styles.card} key={i}>
								<div
									style={{
										backgroundColor: `${d?.color_code}`,
										borderRadius: 10
									}}>
									<div className={styles.card__image}>
										<Image
											alt=''
											src={getImage(d.file)}
											width={331}
											height={254}
											priority
											style={{
												objectFit: 'contain'
											}}
										/>
									</div>
									<div className={styles.card__content}>
										<div className='text-black text-24 text-grey900 fw-700'>{d?.title}</div>
										<div className='w-full'>
											<Link href={children[i]?.link || '/'}>
												<Button
													className='text-18 text-grey900 fw-500 hover-bg-secondary'
													style={{
														width: '100%',
														borderRadius: 6,
														backgroundColor: '#E6BB20',
														fontWeight: 600
													}}
													size='large'
													label={<span className='fw-700'>{d?.subtitle}</span> || ''}
													type='secondary'
												/>
											</Link>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default MostLoveBrands;
