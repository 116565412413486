import { Block } from '@/services/ecommerce/types/blocksTypes';
import ActionsBlock from '../../dynamics/Actions';
import BannerBlock from '../../dynamics/BannerBlock';
import CarouselBlock from '../../dynamics/CarouselBlock';
import CategoryBlock from '../../dynamics/Category';
import FunctionBlock from '../../dynamics/FunctionBlock';
import ImageBlock from '../../dynamics/ImageBlock';
import SideScrollBlock from '../../dynamics/SideScrollBlock';
import TextBlock from '../../dynamics/TextBlock';
import VideoBlock from '../../dynamics/VideoBlock';

interface Props {
	data: any;
	params?: any;
	titleParams?: any;
	start?: number;
}

const BlockContents = ({ data, params, titleParams, start }: Props) => {
	const blockTypeComponents: any = {
		product: (data: Block, i: number) => <SideScrollBlock key={i} sl={i} data={data} titleParams={titleParams} />,
		actions: (data: Block, i: number) => <ActionsBlock key={i} sl={i} data={data} />,
		carousel: (data: Block, i: number) => <CarouselBlock data={data} key={i} sl={i} />,
		category: (data: Block, i: number) => <CategoryBlock key={i} sl={i} data={data} />,
		function: (data: Block, i: number) => (
			<FunctionBlock
				key={i}
				sl={i}
				data={
					{
						...data,
						extra: params || {}
					} as Block
				}
				titleParams={titleParams}
			/>
		),
		banner: (data: Block, i: number) => <BannerBlock key={i} sl={i} data={data} />,
		video: (data: Block, i: number) => <VideoBlock key={i} sl={i} data={data} />,
		image: (data: Block, i: number) => <ImageBlock data={data} key={i} sl={i} />,
		side_scroll: (data: any, i: number) => <SideScrollBlock key={i} sl={i} data={data} />,
		text: (data: Block, i: number) => <TextBlock data={data} key={i} sl={i} />
	};

	return data?.blocks
		?.slice()
		.sort((a: any, b: any) => (a.block_weight || 0) - (b.block_weight || 0))
		.map((data: any, i: number) => {
			const blockType = data?.block_type;
			const componentRenderer = blockTypeComponents[blockType];
			if (componentRenderer) {
				const sl = start ? start + i : i;
				return data ? componentRenderer(data, sl) : '';
			}
			return null;
		});
};

export default BlockContents;

export const BlockContent = ({ data, params, titleParams, start }: Props) => {
	const blockTypeComponents: any = {
		product: (data: Block, i: number) => <SideScrollBlock key={i} sl={i} data={data} titleParams={titleParams} />,
		actions: (data: Block, i: number) => <ActionsBlock key={i} sl={i} data={data} />,
		carousel: (data: Block, i: number) => <CarouselBlock data={data} key={i} sl={i} />,
		category: (data: Block, i: number) => <CategoryBlock key={i} sl={i} data={data} />,
		function: (data: Block, i: number) => (
			<FunctionBlock
				key={i}
				sl={i}
				data={
					{
						...data,
						extra: params || {}
					} as Block
				}
				titleParams={titleParams}
			/>
		),
		banner: (data: Block, i: number) => <BannerBlock key={i} sl={i} data={data} />,
		video: (data: Block, i: number) => <VideoBlock key={i} sl={i} data={data} />,
		image: (data: Block, i: number) => <ImageBlock data={data} key={i} sl={i} />,
		side_scroll: (data: any, i: number) => <SideScrollBlock key={i} sl={i} data={data} />,
		text: (data: Block, i: number) => <TextBlock data={data} key={i} sl={i} />
	};

	const blockType = data?.block_type;
	const componentRenderer = blockTypeComponents[blockType];
	if (componentRenderer) {
		const sl = start || 0;
		return data ? componentRenderer(data, sl) : null;
	}
	return null;
};
