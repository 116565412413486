import Typing from '@/components/atoms/TypingEffect/Typing';
import Slider from '@/components/molecules/Slider';
import { getImage } from '@/services/ecommerce/utils/helper';
import { ActionBlockData } from '@/services/ecommerce/types/blocksTypes';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { MdArrowRight, MdOutlineTrendingUp } from 'react-icons/md';
import styles from './styles.module.scss';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const MostSearchOnAroggaBeauty: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('py-30 bg-white ', styles.most_search_on)}>
			<div className='container product-slider'>
				<div className={styles.header}>
					<div className={styles.head}>
						<div>
							<div className={styles.title}>
								<Typing text={label} />
							</div>
						</div>
						<div className={styles.icon}>
							<MdOutlineTrendingUp />
						</div>
					</div>
				</div>

				<Slider
					slidesPerView={4.2}
					spaceBetween={20}
					shouldSlidesPerGroup={true}
					responsive={{
						'0': {
							slidesPerView: 0.5
						},
						'640': {
							slidesPerView: 0.5
						},
						'768': {
							slidesPerView: 3,
							spaceBetween: 20
						},
						'800': {
							slidesPerView: 3,
							spaceBetween: 20
						},
						'1024': {
							slidesPerView: 3,
							spaceBetween: 30
						},
						'1280': {
							slidesPerView: 4,
							spaceBetween: 20
						},
						'1400': {
							slidesPerView: 4.2,
							spaceBetween: 20
						},
						'1600': {
							slidesPerView: 4.2,
							spaceBetween: 20
						}
					}}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<Link href={children[i]?.link || '/'} key={i}>
								<div
									className={styles.card}
									style={{
										backgroundColor: d?.color_code
									}}>
									<div className={styles.card__image}>
										<Image
											unoptimized
											src={getImage(d.file)}
											alt={d?.title}
											width={100}
											height={100}
											objectFit='contain'
										/>
									</div>
									<div className='text-grey900 fw-400'>{d?.title}</div>
									<div className={styles.icon}>
										<MdArrowRight />
									</div>
								</div>
							</Link>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default MostSearchOnAroggaBeauty;
