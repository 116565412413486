import generateBlockLink from '@/services/ecommerce/utils/LinkGenerator';
import { Block } from '@/services/ecommerce/types/blocksTypes';
import React from 'react';
import 'swiper/css/pagination';
import {
	BeautyTools,
	Image2Column,
	Image3Column,
	ImageVertical2Column,
	Kbeauty,
	LifeStyle,
	ShopByConcern
} from '../organisms/Dynamics/Images';

interface ImageBlockProps {
	data: Block;
	sl?: any;
}
const ImageBlock: React.FC<ImageBlockProps> = ({ data }) => {
	const { block_data, block_display_type, block, block_color_code, block_view_all_link, block_subtitle } = data;
	const blockLinks = generateBlockLink(data);
	if (block_display_type === 'image_2_column') {
		return <Image2Column data={block_data} label={block} links={blockLinks} />;
	}

	if (block_display_type === 'image_3_column') {
		const links = generateBlockLink(data);
		return <Image3Column data={block_data} label={block} links={links} />;
	}
	//beauty
	if (block_display_type === 'image_vertical_2_column') {
		return <ImageVertical2Column data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'life_style') {
		return <LifeStyle data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'shop_by_concern') {
		return <ShopByConcern data={block_data} label={block} blockLinks={blockLinks} />;
	}
	if (block_display_type === 'beauty_tools') {
		return (
			<BeautyTools
				data={block_data}
				label={block}
				color_code={block_color_code}
				sub_title={block_subtitle}
				blockLinks={blockLinks}
			/>
		);
	}
	if (block_display_type === 'k_beauty') {
		return (
			<Kbeauty
				data={block_data}
				label={block}
				color_code={block_color_code}
				sub_title={block_subtitle}
				blockLinks={blockLinks}
			/>
		);
	}
};

export default ImageBlock;
