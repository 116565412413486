import generateBlockLink from '@/services/ecommerce/utils/LinkGenerator';
import { Block } from '@/services/ecommerce/types/blocksTypes';
import { AllProducts, FeaturedBrands, TitleTop } from '../organisms/Dynamics/Functions';
import PreviouslyBrowsedItems from '../organisms/Dynamics/Functions/PreviouslyBrowsedItems';
import SideScrollBlock from './SideScrollBlock';
interface FunctionBlockProps {
	data: Block;
	titleParams?: any;
	sl?: any;
}

const FunctionBlock: React.FC<FunctionBlockProps> = ({ sl, data, titleParams }) => {
	const { block_display_type, block } = data;

	if (block_display_type === 'featured_brands_sidescroll') {
		return <FeaturedBrands data={data} />;
	}
	if (block_display_type === 'title_top') {
		if (!data.block_data) {
			return null;
		}
		const blockLinks = generateBlockLink(data);
		return <TitleTop data={data} blockLinks={blockLinks} />;
	}

	if (block_display_type === 'sidescroll_vertical') {
		return <SideScrollBlock titleParams={titleParams} data={data} sl={sl} />;
	}
	if (block_display_type === 'previously_browsed_items') {
		return <PreviouslyBrowsedItems data={data} sl={sl} />;
	}
	if (block_display_type === 'healthcare_all_previously_browsed_items') {
		return <PreviouslyBrowsedItems data={data} sl={sl} />;
	}
	if (block_display_type === 'brand_all_products') {
		return <AllProducts data={data} titleParams={titleParams} />;
	}

	return null;
};

export default FunctionBlock;
